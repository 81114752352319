import React from "react"

const Ingredient = ({ slice }) => {
  const ingredient = slice.primary

  // check if there is a vegan alternative
  const hasVeganAlternative = ingredient.vegan_alternative?.document

  const formattedIngredientCount = count => {
    // check if number is fraction
    const isFraction = count % 1 !== 0

    if (isFraction) {
      //split integer and fraction
      const integerPart = Math.floor(count) !== 0 ? Math.floor(count) : ""
      const fraction = count - integerPart

      // format integer and fraction
      switch (fraction) {
        case 0.5:
          return `${integerPart}${String.fromCharCode(189)}`
        case 0.25:
          return `${integerPart}${String.fromCharCode(188)}`
        case 0.75:
          return `${integerPart}${String.fromCharCode(190)}`
        default:
          return count
      }
    } else {
      return count
    }
  }

  const formattedIngredient = ingredient => {
    const count = formattedIngredientCount(ingredient.count)
      ? formattedIngredientCount(ingredient.count)
      : ""
    const unit = ingredient.unit ? ingredient.unit : ""
    const nameSingular = ingredient.ingredient.document.data.name.text
    const namePlural = ingredient.ingredient.document.data.name_plural.text
      ? ingredient.ingredient.document.data.name_plural.text
      : nameSingular

    // only pluralise ingredients with the following units
    if (count > 1) {
      if (
        unit === "g" ||
        unit === "kg" ||
        unit === "small" ||
        unit === "medium" ||
        unit === "large" ||
        unit === ""
      ) {
        return `${count} ${unit} ${namePlural} `
      } else {
        return `${count} ${unit} ${nameSingular} `
      }
    } else if (count === 1) {
      return `${count} ${unit} ${nameSingular} `
    } else {
      return (
        <>
          <span className="fraction">{count}</span> {unit} {nameSingular}{" "}
        </>
      )
    }
  }

  return (
    <p>
      {formattedIngredient(ingredient)}
      {ingredient.short_description1.text && (
        <>({ingredient.short_description1.text})</>
      )}
      {hasVeganAlternative && (
        <>{` / ${ingredient.vegan_alternative.document.data.name.text}`}</>
      )}
    </p>
  )
}

export default Ingredient
