import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import Seo from "@components/Seo"
import SliceZone from "@components/SliceZone"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

const Page = ({ data }) => {
  if (!data) return null

  const page = data.prismicPage.data

  return (
    <Layout>
      <div className="page">
        <SliceZone sliceZone={page.body} />
      </div>
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const page = data.prismicPage.data
  return (
    <Seo
      title={page.title.text}
      description={page.short_description.text}
      location={location}
      canonicalLink={page.canonical_link?.url}
    >
      {!page.is_indexable && <meta name={`robots`} content={`noindex`} />}
    </Seo>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        title {
          text
        }
        short_description {
          text
        }
        image {
          url
          alt
        }
        is_indexable
        canonical_link {
          url
        }
        body {
          ... on PrismicPageDataBodyInstagramSection {
            id
            slice_type
            primary {
              instagram_cta_text {
                text
                richText
              }
              instagram_cta_url {
                url
                target
              }
              instagram_subtitle {
                text
                richText
              }
              instagram_title {
                text
                richText
              }
            }
          }
          ... on PrismicPageDataBodyTrustpilotScoreSection {
            id
            slice_type
            primary {
              trustpilot_title {
                text
                richText
              }
              trustpilot_subtitle {
                text
                richText
              }
              trustpilot_cta_url {
                url
                target
              }
              trustpilot_section_type
              trustpilot_cta_text {
                text
              }
            }
          }
          ... on PrismicPageDataBodyColumnSection {
            id
            slice_type
            primary {
              number_of_columns
              columns_title {
                text
                richText
              }
              columns_subtitle {
                text
                richText
              }
            }
            items {
              cta_url {
                url
                link_type
                target
              }
              cta_text {
                text
              }
              column_title {
                text
                richText
              }
              column_text {
                text
                richText
              }
              column_subtitle {
                text
                richText
              }
              column_image {
                alt
                url
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 580
                  placeholder: NONE
                )
              }
            }
          }
          ... on PrismicPageDataBodyNewsletterSection {
            id
            primary {
              newsletter_title {
                text
                richText
              }
              newsletter_subtitle {
                text
                richText
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodyAccordionSection {
            id
            slice_type
            primary {
              accordion_title {
                text
                richText
              }
              accordion_subtitle {
                text
                richText
              }
            }
            items {
              accordion_item_title {
                text
                richText
              }
              accordion_item_text {
                text
                richText
              }
            }
          }
          ... on PrismicPageDataBodyHeroSection {
            id
            slice_type
            primary {
              hero_card_title {
                text
                richText
              }
              hero_card_text {
                text
                richText
              }
              hero_card_image {
                url
                alt
                gatsbyImageData(width: 940, placeholder: NONE)
              }
              background_colour
              hero_card_cta_text {
                text
              }
              hero_card_cta_url {
                url
                link_type
                target
              }
              hero_card_orientation
            }
          }
          ... on PrismicPageDataBodyCuratedPostsSection {
            id
            slice_type
            items {
              post {
                url
                document {
                  ... on PrismicPost {
                    id
                    data {
                      title {
                        text
                        richText
                      }
                      short_description {
                        text
                        richText
                      }
                      image {
                        url
                        alt
                        gatsbyImageData(
                          width: 360
                          height: 360
                          placeholder: NONE
                        )
                      }
                      authors {
                        author {
                          document {
                            ... on PrismicAuthor {
                              id
                              url
                              data {
                                full_name {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      date(formatString: "D MMMM YYYY")
                    }
                    url
                  }
                  ... on PrismicRecipe {
                    id
                    data {
                      title {
                        text
                        richText
                      }
                      short_description {
                        text
                        richText
                      }
                      image {
                        url
                        alt
                        gatsbyImageData(
                          width: 360
                          height: 360
                          placeholder: NONE
                        )
                      }
                      authors {
                        author {
                          document {
                            ... on PrismicAuthor {
                              id
                              url
                              data {
                                full_name {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      date(formatString: "D MMMM YYYY")
                    }
                    url
                  }
                }
              }
            }
            primary {
              curated_posts_subtitle {
                text
                richText
              }
              curated_posts_title {
                text
                richText
              }
            }
          }
          ... on PrismicPageDataBodyBodyText {
            id
            slice_type
            slice_label
            items {
              body_text {
                text
                richText
              }
            }
          }
          ... on PrismicPageDataBodyCodeBlockSection {
            id
            slice_type
            primary {
              code_block {
                text
              }
            }
          }
          ... on PrismicPageDataBodyCtaSection {
            id
            slice_type
            slice_label
            primary {
              cta_link {
                url
                link_type
                target
              }
              cta_label
            }
          }
          ... on PrismicPageDataBodyCarousel {
            id
            slice_type
            items {
              description
              carousel_image {
                alt
                gatsbyImageData(
                  width: 1200
                  height: 600
                  layout: CONSTRAINED
                  breakpoints: [576, 768, 1024, 1216]
                )
              }
            }
            primary {
              title1 {
                text
              }
              subtitle {
                richText
              }
            }
          }

          ... on PrismicPageDataBodyLeadGenSection {
            id
            primary {
              lead_gen_cta_text
              lead_gen_event_name
              lead_gen_description {
                richText
              }
              lead_gen_disclaimer {
                richText
              }
              lead_gen_title {
                richText
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodyBanner {
            id
            primary {
              banner {
                document {
                  ... on PrismicBanner {
                    id
                    _previewable
                    data {
                      background_colour
                      cta_position
                      cta_text
                      cta_url {
                        url
                        target
                        link_type
                      }
                      description {
                        richText
                        text
                      }
                      image {
                        alt
                        gatsbyImageData
                      }
                      text_colour
                      title {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Page)
