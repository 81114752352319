import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "@components/Navigation"
import MobileNavigation from "@components/MobileNavigation"
import "@styles/components/header.scss"
import Logo from "../assets/images/Oddbox-logo.svg"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      prismicNavigationMenu(uid: { eq: "primary" }) {
        id
        data {
          body {
            ... on PrismicNavigationMenuDataBodyLevel1Link {
              id
              items {
                link {
                  url
                  link_type
                  target
                }
                link_label
              }
              primary {
                link {
                  url
                  link_type
                  target
                }
                link_label
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  const navLinks = data.prismicNavigationMenu.data

  return (
    <header className="header">
      <div className="header__nav">
        <MobileNavigation navLinks={navLinks} />

        <div className="header__logo">
          <Link to="/">
            <img src={Logo} alt="Oddbox" style={{ width: "180px" }} />
          </Link>
        </div>
        <Navigation navLinks={navLinks} />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
