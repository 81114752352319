import React from "react"
import CustomLink from "@components/helpers/CustomLink"

import "@styles/components/slices/cta-section.scss"

const CTASection = ({ slice }) => {
  return (
    <section
      className={
        slice.slice_label ? `cta-section ${slice.slice_label}` : "cta-section"
      }
    >
      <CustomLink
        className="button button-primary"
        url={slice.primary.cta_link.url}
        aria-label={slice.primary.cta_label}
        target={slice.primary.cta_link.target}
        type={slice.primary.cta_link.link_type}
      >
        {slice.primary.cta_label}
      </CustomLink>
    </section>
  )
}

export default CTASection
