import React from "react"
import RichText from "@components/RichText"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from "@components/helpers/CustomLink"
import classnames from "classnames"

import "@styles/components/slices/hero-card.scss"

const HeroCard = ({ slice }) => {
  const {
    hero_card_title,
    hero_card_text,
    hero_card_cta_url,
    hero_card_cta_text,
    hero_card_image,
    background_colour,
    hero_card_orientation,
  } = slice.primary

  const heroCardClassNames = classnames({
    "hero-card": true,
    "hero-card--right": hero_card_orientation === "Image Right",
    "hero-card--left": hero_card_orientation === "Image Left",
    "background-alt": background_colour,
  })

  return (
    <section className={heroCardClassNames}>
      <div className="hero-card__copy">
        <RichText render={hero_card_title.richText} />
        <RichText render={hero_card_text.richText} />
        {hero_card_cta_text.text && hero_card_cta_url.url && (
          <CustomLink
            className={"button button-primary"}
            url={hero_card_cta_url.url}
            target={hero_card_cta_url.target}
            label={hero_card_cta_text.text}
            type={hero_card_cta_url.link_type}
          >
            {hero_card_cta_text.text}
          </CustomLink>
        )}
      </div>
      <div className="hero-card__image">
        <GatsbyImage
          image={hero_card_image.gatsbyImageData}
          alt={hero_card_image.alt || "Oddbox image"}
        />
      </div>
    </section>
  )
}

export default HeroCard
