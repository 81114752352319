import React from "react"
import RichText from "@components/RichText"
import CustomLink from "@components/helpers/CustomLink"
import BlogPostCard from "@components/BlogPostCard"
import { useStaticQuery, graphql } from "gatsby"

import "@styles/components/slices/curated-posts-section.scss"
import "@styles/components/slices/columns-section.scss"

const CuratedRecipeSection = ({ slice }) => {
  const { curated_posts_title, curated_posts_subtitle } = slice.primary

  // Last minute gross change to use latest recipes
  // TODO add bool to toggle use latest posts or provide curated posts in Prismic
  const data = useStaticQuery(
    graphql`{
  allPrismicRecipe(limit: 4, sort: {data: {date: DESC}}) {
    nodes {
      url
      id
      data {
        title {
          text
          richText
        }
        image {
          alt
          gatsbyImageData(width: 360, height: 360, placeholder: NONE)
        }
        short_description {
          text
        }
      }
    }
  }
}`
  )

  const recipes = data.allPrismicRecipe.nodes

  return (
    <section className="curated-posts-section curated-recipe-section">
      <div className="curated-posts-section__title">
        <RichText render={curated_posts_title.richText} />
      </div>
      <div className="curated-posts-section__subtitle">
        <RichText render={curated_posts_subtitle.richText} />
      </div>
      <div className="columns-section__columns columns-section__4-columns">
        {recipes.map(recipe => {
          const recipeObject = recipe.data
          recipeObject.url = recipe.url
          return (
            <BlogPostCard
              key={recipe.id}
              blogPost={recipeObject}
              hideByline={true}
            />
          )
        })}
      </div>
      {slice.primary.curated_posts_cta_label &&
        slice.primary.curated_posts_cta_link && (
          <div className="curated-posts-section__cta">
            <CustomLink
              className="button button-primary"
              target={slice.primary.curated_posts_cta_link.target}
              url={slice.primary.curated_posts_cta_link.url}
              type={slice.primary.curated_posts_cta_link.link_type}
              label={slice.primary.curated_posts_cta_label}
            >
              {slice.primary.curated_posts_cta_label}
            </CustomLink>
          </div>
        )}
    </section>
  )
}

export default CuratedRecipeSection
