import { useEffect, useState } from 'react';

export const SCREENS = {
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1216,
    xxl: 1408,
};

export function useScreenMediaQuery() {
    const [width, setWidth] = useState(() => {
        if (typeof window !== `undefined`) {
            return window.innerWidth;
        }
        return 0; // Or any default value you prefer
    });

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        if (typeof window !== `undefined`) {
            window.addEventListener('resize', handleWindowResize);
            return () => window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return {
        isDownSM: width < SCREENS.sm,
        isDownMD: width < SCREENS.md,
        isDownLG: width < SCREENS.lg,
        isDownXL: width < SCREENS.xl,
        isDownXXL: width < SCREENS.xxl,

        isUpSM: width >= SCREENS.sm,
        isUpMD: width >= SCREENS.md,
        isUpLG: width >= SCREENS.lg,
        isUpXL: width >= SCREENS.xl,
        isUpXXL: width >= SCREENS.xxl,
    };
}
