import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "@components/Layout"
import Seo from "@components/Seo"
import SliceZone from "@components/SliceZone"
import Byline from "@components/Byline"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { recipeSchema } from "@utils/schemaBuilder"
import RichText from "@components/RichText"
import ImageGallery from "@components/slices/ImageGallery"

import "@styles/components/print.scss"
import "@styles/components/recipe.scss"
import Banner from "@components/Banner"

const RecipePage = ({ data, location }) => {
  if (!data) return null
  const recipe = data.prismicRecipe.data

  const banner =
    data.prismicSiteConfig?.data.recipe_page_banner.document.data || null
  const hasBannerSlice = recipe.body.find(
    slice => slice.slice_type === "banner",
  )

  return (
    <Layout>
      <article className="post recipe container">
        <button
          className="recipe__print-button text-link"
          onClick={() => {
            window.print()
          }}
        >
          Print recipe
        </button>
        <Byline authors={recipe.authors} date={recipe.date} />
        <h1 className="post__title is-centered">{recipe.title.text}</h1>
        <div className="post__description is-centered">
          <RichText render={recipe.short_description.richText} />
        </div>
        <div className="recipe__columns">
          <div className="recipe__content">
            <div className="recipe__description">
              {recipe.description.text && (
                <div className="recipe__long-description">
                  <RichText render={recipe.description.richText} />
                </div>
              )}
              <p>
                Prep time: {recipe.preparation_time} minute
                {recipe.preparation_time > 1 ? "s" : ""}
              </p>
              <p>
                Cook time: {recipe.cooking_time} minute
                {recipe.cooking_time > 1 ? "s" : ""}
              </p>
              <p>
                Total time: {recipe.preparation_time + recipe.cooking_time}{" "}
                minute
                {recipe.preparation_time + recipe.cooking_time > 1 ? "s" : ""}
              </p>
              <p>Servings: {recipe.servings}</p>
            </div>
            <div className="recipe__ingredients">
              <h3>Ingredients:</h3>
              <SliceZone sliceZone={recipe.body} />
            </div>
            <div>
              <h3>Recipe:</h3>
              <ol>
                {recipe.steps.map((step, index) => {
                  if (step.step_text.text) {
                    return <li key={`step-${index}`}>{step.step_text.text}</li>
                  } else {
                    return null
                  }
                })}
              </ol>
            </div>
          </div>
          <div className="recipe__image">
            {recipe.image?.gatsbyImageData && (
              <GatsbyImage
                image={recipe.image.gatsbyImageData}
                alt={recipe.image.alt || "Oddbox image"}
              />
            )}
          </div>
        </div>
        <hr />
        <div>
          {recipe.storage.text && (
            <>
              <h3>How to store</h3>
              <p>{recipe.storage.text}</p>
            </>
          )}
          {recipe.leftovers.text && (
            <>
              <h3>Leftovers</h3>
              <p>{recipe.leftovers.text}</p>
            </>
          )}
          <div className="recipe__gallery">
            {recipe.steps.map((step, index) => {
              if (step.step_image?.gatsbyImageData) {
                return (
                  <div key={`image-${index}`} className="recipe__gallery-image">
                    <GatsbyImage
                      image={step.step_image.gatsbyImageData}
                      alt={step.step_image.alt || "Oddbox image"}
                    />
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
          <SliceZone sliceZone={recipe.body1} />
          <ImageGallery slice={{ items: recipe.gallery }} />
        </div>
      </article>
      {banner && !hasBannerSlice && (
        <Banner
          title={banner.title}
          description={banner.description}
          cta_text={banner.cta_text}
          cta_url={banner.cta_url}
          cta_position={banner.cta_position}
          text_colour={banner.text_colour}
          background_colour={banner.background_colour}
          image={banner.image}
        />
      )}
    </Layout>
  )
}

export const Head = ({ data, location }) => {
  const recipe = data.prismicRecipe.data

  const ingredients = recipe.body.filter(slice => {
    return slice.slice_type === "ingredient" || "ingredient_group"
  })

  // format ingredients and group_ingredients into a single array
  const formattedIngredients = ingredients
    .map(slice => {
      if (slice.slice_type === "ingredient") {
        return slice
      } else if (slice.slice_type === "ingredient_group") {
        const ingredientGroup = slice.items.map(ingredient => {
          let formattedSlice = { primary: ingredient }
          return formattedSlice
        })
        return ingredientGroup
      } else {
        return null
      }
    })
    .flat(1) // flatten array 1 level for group ingredients

  // build schema for recipe - https://schema.org/Recipe
  const schema = recipeSchema(recipe, formattedIngredients)

  return (
    <Seo
      title={recipe.meta_title || recipe.title.text}
      description={recipe.meta_description || recipe.short_description.text}
      location={location}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Seo>
  )
}

export const query = graphql`
  query RecipePageQuery($id: String) {
    prismicRecipe(id: { eq: $id }) {
      _previewable
      data {
        meta_title
        meta_keywords
        meta_description
        cooking_time
        title {
          text
        }
        authors {
          author {
            url
            document {
              ... on PrismicAuthor {
                id
                data {
                  full_name {
                    text
                  }
                  homepage {
                    url
                  }
                  image {
                    url
                    alt
                  }
                  long_description {
                    text
                  }
                  short_description {
                    text
                  }
                }
              }
            }
          }
        }
        date(formatString: "D MMMM YYYY")
        description {
          text
        }
        gallery {
          gallery_image {
            url
            alt
            gatsbyImageData(width: 360, height: 360, placeholder: NONE)
          }
        }
        image {
          url
          alt
          gatsbyImageData(width: 755, height: 1200, placeholder: NONE)
        }
        preparation_time
        servings
        body {
          ... on PrismicRecipeDataBodyIngredient {
            id
            slice_type
            primary {
              count
              unit
              short_description1 {
                text
                richText
              }
              ingredient {
                id
                document {
                  ... on PrismicIngredient {
                    id
                    data {
                      name {
                        text
                      }
                      name_plural {
                        text
                      }
                      oddbox_produce_code
                      short_description {
                        text
                      }
                    }
                  }
                }
                type
              }
              vegan_alternative {
                document {
                  ... on PrismicIngredient {
                    id
                    data {
                      name {
                        text
                        richText
                      }
                      name_plural {
                        text
                        richText
                      }
                      short_description {
                        text
                        richText
                      }
                      oddbox_produce_code
                    }
                  }
                }
              }
            }
          }
          ... on PrismicRecipeDataBodyIngredientGroup {
            id
            slice_type
            primary {
              ingredient_group_title {
                text
                richText
              }
            }
            items {
              count
              ingredient {
                document {
                  ... on PrismicIngredient {
                    id
                    data {
                      short_description {
                        text
                        richText
                      }
                      oddbox_produce_code
                      name_plural {
                        text
                        richText
                      }
                      name {
                        text
                        richText
                      }
                    }
                  }
                }
              }
              short_description1 {
                text
                richText
              }
              unit
              vegan_alternative {
                document {
                  ... on PrismicIngredient {
                    id
                    data {
                      name {
                        text
                        richText
                      }
                      name_plural {
                        text
                        richText
                      }
                      oddbox_produce_code
                      short_description {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicRecipeDataBody1Carousel {
            id
            slice_type
            items {
              description
              carousel_image {
                alt
                gatsbyImageData(
                  width: 1200
                  height: 600
                  layout: CONSTRAINED
                  breakpoints: [576, 768, 1024, 1216]
                  placeholder: NONE
                )
              }
            }
            primary {
              title1 {
                text
              }
              subtitle {
                richText
              }
            }
          }
          ... on PrismicRecipeDataBody1CuratedPostsSection {
            id
            slice_type
            items {
              post {
                url
                document {
                  ... on PrismicPost {
                    id
                    data {
                      title {
                        text
                        richText
                      }
                      short_description {
                        text
                        richText
                      }
                      image {
                        url
                        alt
                        gatsbyImageData(
                          width: 360
                          height: 360
                          placeholder: NONE
                        )
                      }
                      authors {
                        author {
                          document {
                            ... on PrismicAuthor {
                              id
                              url
                              data {
                                full_name {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      date(formatString: "D MMMM YYYY")
                    }
                    url
                  }
                  ... on PrismicRecipe {
                    id
                    data {
                      title {
                        text
                        richText
                      }
                      short_description {
                        text
                        richText
                      }
                      image {
                        url
                        alt
                        gatsbyImageData(
                          width: 360
                          height: 360
                          placeholder: NONE
                        )
                      }
                      authors {
                        author {
                          document {
                            ... on PrismicAuthor {
                              id
                              url
                              data {
                                full_name {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      date(formatString: "D MMMM YYYY")
                    }
                    url
                  }
                }
              }
            }
            primary {
              curated_posts_subtitle {
                text
                richText
              }
              curated_posts_title {
                text
                richText
              }
            }
          }
        }
        body2 {
          ... on PrismicRecipeDataBody2Banner {
            id
            primary {
              banner {
                document {
                  ... on PrismicBanner {
                    id
                    _previewable
                    data {
                      background_colour
                      cta_position
                      cta_text
                      cta_url {
                        url
                        target
                        link_type
                      }
                      description {
                        richText
                        text
                      }
                      image {
                        alt
                        gatsbyImageData
                      }
                      text_colour
                      title {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
        }
        gallery {
          gallery_image {
            gatsbyImageData(width: 360, height: 360, placeholder: NONE)
          }
        }
        steps {
          step_image {
            alt
            url
            gatsbyImageData(width: 360, height: 360, placeholder: NONE)
          }
          step_text {
            text
          }
        }
        short_description {
          text
          richText
        }
        description {
          text
          richText
        }
        difficulty
        leftovers {
          text
        }
        storage {
          text
        }
        tags {
          tag {
            id
          }
        }
        category
      }
    }
    prismicSiteConfig {
      _previewable
      data {
        recipe_page_banner {
          document {
            ... on PrismicBanner {
              id
              data {
                title {
                  richText
                }
                text_colour
                image {
                  gatsbyImageData
                  alt
                }
                description {
                  richText
                }
                cta_url {
                  url
                  target
                  link_type
                }
                cta_text
                cta_position
                background_colour
              }
            }
          }
        }
      }
    }
  }
`
export default withPrismicPreview(RecipePage)
