import React from "react"
import RichText from "@components/RichText"

import "@styles/components/slices/newsletter-section.scss"

const NewsletterSection = ({ slice }) => {
  const { newsletter_title, newsletter_subtitle } = slice.primary

  return (
    <section className="newsletter-section">
      <RichText render={newsletter_title.richText} />
      <RichText render={newsletter_subtitle.richText} />
      {/* ***** TODO ******
          Add form to handle newsletter form
       */}
    </section>
  )
}

export default NewsletterSection
