import React from "react"
import RichText from "@components/RichText"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from "@components/helpers/CustomLink"

const CommunitySection = ({ slice }) => {
  const community = slice.primary
  return (
    <section className="community-section">
      <div className="community-section__wrapper">
        <div className="community-section__title">
          <h2>{community.community_title.text}</h2>
        </div>
        <div className="community-section__columns">
          {slice.items.map((item, index) => {
            return (
              <div
                key={`community-section-column-${index}`}
                className="community-section__column"
              >
                <GatsbyImage
                  className="community-section__image"
                  image={item.community_image.gatsbyImageData}
                  alt={item.community_image.alt}
                  objectFit="contain"
                />
                <div>
                  <div className="community-section__description">
                    <RichText render={item.community_description.richText} />
                  </div>
                  <div className="community-section__links">
                    <span className="community-section__annotation">
                      {item.community_cta_annotation}
                    </span>
                    <br />
                    <CustomLink
                      className="button button-primary"
                      target={item.community_cta_link.target}
                      url={item.community_cta_link.url}
                      type={item.community_cta_link.link_type}
                      label={item.community_cta_label}
                    >
                      {item.community_cta_label}
                    </CustomLink>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CommunitySection
