import React from "react"
import RichText from "@components/RichText"
import { GatsbyImage } from "gatsby-plugin-image"
import "@styles/components/slices/card-section.scss"

const CardSection = ({ slice }) => {
  return (
    <section className="card-section">
      {slice.items.map((card, index) => {
        return (
          <div
            key={`card-section-${index}`}
            className={
              card.background ? `card-section__card--alt` : `card-section__card`
            }
          >
            <div className="card-section__copy-column">
              <h2>{card.card_section_title.text}</h2>
              <RichText render={card.card_section_copy.richText} />
            </div>
            <div
              className={
                card.image_position
                  ? `card-section__image-column--right`
                  : `card-section__image-column--left`
              }
            >
              <GatsbyImage
                image={card.card_section_image.gatsbyImageData}
                alt={card.card_section_image.alt || "Oddbox image"}
              />
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default CardSection
