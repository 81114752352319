import React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import CookieWrapper from "@components/helpers/CookieWrapper"
import { unpublishedRepositoryConfigs } from "@utils/prismicUnpublishedPreviews"


export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={unpublishedRepositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
)

export const wrapPageElement = ({ element, props }) => (
  <CookieWrapper {...props}>{element}</CookieWrapper>
)
