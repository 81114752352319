import React from "react"
import Ingredient from "@slices/Ingredient"

const IngredientGroup = ({ slice }) => {
  const title = slice.primary.ingredient_group_title
  const ingredients = slice.items

  return (
    <div className="recipe__ingredient-group">
      {title.text && <h3>{title.text}</h3>}

      {ingredients.map((ingredient, index) => {
        // format ingredient into a slice
        const ingredientSlice = { primary: ingredient }

        return <Ingredient key={`slice-${index}`} slice={ingredientSlice} />
      })}
    </div>
  )
}

export default IngredientGroup
