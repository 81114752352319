import React from "react"
import RichText from "@components/RichText"
import BlogPostCard from "@components/BlogPostCard"
import CustomLink from "@components/helpers/CustomLink"

import "@styles/components/slices/curated-posts-section.scss"
import "@styles/components/slices/columns-section.scss"

const CuratedPostsSection = ({ slice }) => {
  const { curated_posts_title, curated_posts_subtitle } = slice.primary

  return (
    <section
      className={
        slice.slice_type === "curated_recipe_section"
          ? "curated-posts-section curated-recipe-section"
          : "curated-posts-section"
      }
    >
      <div className="curated-posts-section__title">
        <h3>{curated_posts_title.text}</h3>
      </div>
      <div className="curated-posts-section__subtitle">
        <RichText render={curated_posts_subtitle.richText} />
      </div>
      <div className="columns-section__columns columns-section__3-columns">
        {slice.items.map(post => {
          if (post.post?.document?.data) {
            const blogPost = post.post.document.data
            blogPost.url = post.post.document.url
            return (
              <BlogPostCard
                key={post.post.document.id}
                blogPost={blogPost}
                hideByline={true}
              />
            )
          } else {
            return null
          }
        })}
      </div>
      {slice.primary.curated_posts_cta_label &&
        slice.primary.curated_posts_cta_link && (
          <div className="curated-posts-section__cta">
            <CustomLink
              className="button button-primary"
              target={slice.primary.curated_posts_cta_link.target}
              url={slice.primary.curated_posts_cta_link.url}
              type={slice.primary.curated_posts_cta_link.link_type}
              label={slice.primary.curated_posts_cta_label}
            >
              {slice.primary.curated_posts_cta_label}
            </CustomLink>
          </div>
        )}
    </section>
  )
}

export default CuratedPostsSection
