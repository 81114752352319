// Recipe Schema: https://schema.org/Recipe
export function recipeSchema(recipe, ingredients) {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "Recipe",
    name: recipe.title.text,
    image: [recipe.image.url],
    datePublished: recipe.date,
    description: recipe.short_description.text,
    prepTime: `PT${recipe.preparation_time}M`,
    cookTime: `PT${recipe.cooking_time}M`,
    totalTime: `PT${recipe.preparation_time + recipe.cooking_time}M`,
    recipeYield: recipe.servings,
    recipeCategory: recipe.category,
    recipeIngredient: ingredients.map(ingredient => {
      return `${ingredient.primary.count ? ingredient.primary.count : ""}${
        ingredient.primary.unit ? ingredient.primary.unit : ""
      } ${
        ingredient.primary.ingredient.document.data.name.text
          ? ingredient.primary.ingredient.document.data.name.text
          : ""
      }`
    }),
    recipeInstructions: recipe.steps
      .filter(step => {
        return step.step_text.text
      })
      .map((step, index) => {
        const howToStep = {
          "@type": "HowToStep",
          name: `Step ${String(index + 1)}`,
          text: step.step_text.text,
          image: step.step_image.url,
        }
        return howToStep
      }),
  }

  // add author to schema
  if (recipe.authors[0] && recipe.authors[0].author?.document) {
    schema.author = {
      "@type": "Person",
      name: recipe.authors[0].author.document.data.full_name.text, // return single author
    }
  }

  return schema
}

export const blogSchema = (post, location) => {
  return {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": location.href,
    },
    headline: post.data.title.text,
    image: [post.data.image.url],
    datePublished: post.data.date,
    dateModified: post.last_publication_date,
    author: {
      "@type": "Person",
      name: post.data.authors[0].author.document.data.full_name.text,
      url: `${location.origin}${post.data.authors[0].author.url}`,
    },
    publisher: {
      "@type": "Organization",
      name: "Oddbox",
      logo: {
        "@type": "ImageObject",
        url: "https://www.oddbox.co.uk/static/161a4c925cbc517745352d68883dba6a/79f07/Oddbox-logo.webp",
      },
    },
  }
}

export const organizationSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Oddbox Delivery Ltd",
    url: "https://www.oddbox.co.uk/",
    logo: "https://www.oddbox.co.uk/static/161a4c925cbc517745352d68883dba6a/79f07/Oddbox-logo.webp",
    alternateName: "Oddbox",
    sameAs: [
      "https://www.facebook.com/OddboxLDN/",
      "https://www.instagram.com/oddboxldn/",
      "https://twitter.com/OddboxLDN",
      "https://www.linkedin.com/company/oddbox/",
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "020 3318 4987",
        contactType: "customer service",
        areaServed: "GB",
        availableLanguage: "en",
      },
    ],
  }
}

export const productSchema = (product, location) => {
  return {
    "@context": "https://schema.org/",
    "@type": "ItemList",
    url: location.href,
    numberOfItems: product.products?.length,
    itemListElement: product.products?.map(productItem => {
      return {
        "@type": "Product",
        sku: productItem.sku,
        image: [productItem.product_images.gatsbyImageData.images.fallback.src],
        name: productItem.name,
        description: productItem.teaser,
        brand: {
          "@type": "Brand",
          name: "Oddbox",
        },
        offers: {
          "@type": "Offer",
          url: location.href,
          priceCurrency: "GBP",
          price: productItem.price,
          availability: "https://schema.org/InStock",
        },
      }
    }),
  }
}
