import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "@styles/components/footer.scss"
import CustomLink from "@components/helpers/CustomLink"
import facebookIcon from "../assets/images/Facebook.svg"
import linkedInIcon from "../assets/images/LinkedIn.svg"
import twitterIcon from "../assets/images/Twitter.svg"
import instagramIcon from "../assets/images/Instagram.svg"

const Footer = () => {
  const browser = typeof window !== "undefined" && window;
  const data = useStaticQuery(graphql`
    {
      prismicNavigationMenu(uid: { eq: "footer" }) {
        id
        data {
          body {
            ... on PrismicNavigationMenuDataBodyLevel1Link {
              id
              primary {
                link {
                  url
                  link_type
                  target
                }
                link_label
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  const links = data.prismicNavigationMenu.data.body

  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__top-column">
          <h2>Links</h2>
          <ul className="footer__links">
            {links.map((link, index) => {
              return (
                <li key={`footerLink-${index}`}>
                  <CustomLink
                    target={link.primary.link.target}
                    url={link.primary.link.url}
                    type={link.primary.link.link_type}
                    label={link.primary.link_label}
                  >
                    {link.primary.link_label}
                  </CustomLink>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footer__top-column">
          <h2>FOLLOW US</h2>
          <a href="/newsletter" className="button button-tertiary">
            Newsletter
          </a>
          <div>
            <ul className="footer__socials">
              <li>
                <a
                  href="https://www.facebook.com/OddboxLDN/"
                  className="footer__social-link"
                >
                  <img src={facebookIcon} alt="Facebook" loading="lazy" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/oddboxldn/"
                  className="footer__social-link"
                >
                  <img src={instagramIcon} alt="Instagram" loading="lazy" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/OddboxLDN"
                  className="footer__social-link"
                >
                  <img src={twitterIcon} alt="Twitter" loading="lazy" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/oddbox/"
                  className="footer__social-link"
                >
                  <img src={linkedInIcon} alt="LinkedIn" loading="lazy" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__top-column">
          <h2>Get in touch</h2>
          <a
            className="button button-tertiary"
            href="https://support.oddbox.co.uk/hc/en-gb"
          >
            Help and support
          </a>
        </div>
        <div className="footer__top-column center">
          <div className="footer__bcorp-logo"></div>
        </div>
      </div>
      <div className="footer__middle">
        <p>
          <Link to="/terms-and-conditions">
            TERMS &amp; CONDITIONS | PRIVACY POLICY
          </Link>
          <span>&nbsp;</span>
          <Link to="/terms-conditions-oddisgood">
            TERMS &amp; CONDITIONS | SOCIAL MEDIA
          </Link>
          <span>&nbsp;</span>
          {browser.CookieControl && (
            <button onClick={() => browser.CookieControl.open()}>
              MANAGE COOKIES
            </button>
          )}
        </p>
      </div>
      <div className="footer__bottom">
        <p>© {new Date().getFullYear()} Oddbox Delivery Ltd </p>
        <p>Registered in England No. 9638976</p>
      </div>
    </footer>
  )
}

export default Footer
