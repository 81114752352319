/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */


import { linkResolver } from './linkResolver'

import Page from "../pages/{PrismicPage.url}"
import Post from "../pages/{PrismicPost.url}"
import Recipe from "../pages/{PrismicRecipe.url}"

export const unpublishedRepositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
    componentResolver: {
      page: Page,
      post: Post,
      recipe: Recipe,
    },
  },
]
