import React, { useState } from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import "../styles/components/accordion.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const Accordion = props => {
  const { index, title } = props
  const [isOpen, setIsOpen] = useState(false || props.open)

  // set classNames based on conditions
  const accordianClassName = classnames(
    "accordion__container",
    { open: isOpen },
    { alt: props.alt }
  )

  const handleClick = e => {
    setIsOpen(!isOpen)
    e.target.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className={accordianClassName} key={index}>
      <button
        id={"accordion-" + index}
        onClick={e => handleClick(e)}
        className="accordion__item-title"
        aria-expanded={isOpen ? "true" : "false"}
        aria-controls={"accordionRegion-" + index}
      >
        {title}
        <FontAwesomeIcon className="icon" icon={faChevronDown} />
      </button>
      <div
        id={"accordionRegion-" + index}
        aria-labelledby={"accordion-" + index}
        className="accordion__item-text"
        role="region"
      >
        <div className="accordion__body"> {props.children}</div>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  index: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  alt: PropTypes.bool,
}

export default Accordion
