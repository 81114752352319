// function to filter cookies and get cookie by name
export const getCookie = cname => {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

// function to set a cookie
export const setCookie = (cname, cvalue, exdays, domain) => {
  const d = new Date()
  const domainAttribute = domain ? `;domain=${domain}` : ""
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = "expires=" + d.toUTCString()
  document.cookie = `${cname}=${cvalue};${expires};path=/${domainAttribute}`
}
