import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "@components/Layout"
import Seo from "@components/Seo"
import SliceZone from "@components/SliceZone"
import { blogSchema } from "@utils/schemaBuilder"
import Byline from "@components/Byline"
import RichText from "@components/RichText"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import "@styles/components/post.scss"
import Banner from "@components/Banner"

const Post = ({ data, location }) => {
  if (!data) return null

  const post = data.prismicPost.data

  const showImage = post.show_image === true || post.show_image === null

  const banner =
    data.prismicSiteConfig?.data.blog_page_banner.document.data || null
  const hasBannerSlice = post.body.find(slice => slice.slice_type === "banner")

  return (
    <Layout>
      <article className="post">
        <Byline authors={post.authors} date={post.date} />
        <h1 className="post__title is-centered">{post.title.text}</h1>
        <div className="post__description is-centered container">
          <RichText render={post.short_description.richText} />
        </div>
        {post.image?.gatsbyImageData && showImage && (
          <GatsbyImage
            image={post.image.gatsbyImageData}
            className="post__main-image"
            alt={post.image.alt || "Oddbox image"}
          />
        )}

        <SliceZone sliceZone={post.body} />
      </article>
      {banner && !hasBannerSlice && (
        <Banner
          title={banner.title}
          description={banner.description}
          cta_text={banner.cta_text}
          cta_url={banner.cta_url}
          cta_position={banner.cta_position}
          text_colour={banner.text_colour}
          background_colour={banner.background_colour}
          image={banner.image}
        />
      )}
    </Layout>
  )
}

export const Head = ({ data, location }) => {
  const post = data.prismicPost.data
  const schema = blogSchema(data.prismicPost, location)

  return (
    <Seo
      title={post.meta_title || post.title.text}
      description={post.meta_description || post.short_description.text}
      location={location}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Seo>
  )
}

export const query = graphql`
  query PostQuery($id: String) {
    prismicPost(id: { eq: $id }) {
      _previewable
      last_publication_date(formatString: "D MMMM YYYY")
      data {
        meta_title
        meta_keywords
        meta_description
        authors {
          author {
            url
            document {
              ... on PrismicAuthor {
                id
                data {
                  full_name {
                    text
                  }
                  image {
                    alt
                    url
                  }
                  long_description {
                    text
                  }
                  short_description {
                    text
                  }
                }
              }
            }
          }
        }
        show_image
        image {
          url
          alt
          gatsbyImageData(width: 750, placeholder: NONE)
        }
        short_description {
          text
          richText
        }
        tags {
          tag {
            document {
              ... on PrismicTag {
                id
                data {
                  image {
                    alt
                    url
                  }
                  name {
                    text
                  }
                  short_description {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
        body {
          ... on PrismicPostDataBodyListicle {
            id
            primary {
              listicle_description {
                richText
              }
              listicle_image {
                alt
                url
              }
              listicle_link {
                url
              }
              listicle_title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicPostDataBodyHeroSection {
            id
            slice_type
            slice_label
            primary {
              hero_card_cta_text {
                richText
                text
              }
              hero_card_cta_url {
                url
                target
              }
              hero_card_image {
                gatsbyImageData
                alt
                url
              }
              hero_card_orientation
              hero_card_text {
                richText
                text
              }
              hero_card_title {
                richText
                text
              }
            }
          }
          ... on PrismicPostDataBodyBodyText {
            id
            slice_type
            slice_label
            items {
              body_text {
                richText
                text
              }
            }
          }
          ... on PrismicPostDataBodyImageGallery {
            id
            slice_type
            items {
              gallery_image {
                alt
                url
                gatsbyImageData(width: 360, height: 360, placeholder: NONE)
              }
            }
          }
          ... on PrismicPostDataBodyCarousel {
            id
            slice_type
            items {
              description
              carousel_image {
                alt
                gatsbyImageData(
                  width: 1200
                  height: 600
                  breakpoints: [576, 768, 1024, 1216]
                  placeholder: NONE
                )
              }
            }
            primary {
              title1 {
                text
              }
              subtitle {
                richText
              }
            }
          }
          ... on PrismicPostDataBodyCuratedPostsSection {
            id
            slice_type
            items {
              post {
                url
                document {
                  ... on PrismicPost {
                    id
                    data {
                      title {
                        text
                        richText
                      }
                      short_description {
                        text
                        richText
                      }
                      image {
                        url
                        alt
                        gatsbyImageData(
                          width: 360
                          height: 360
                          placeholder: NONE
                        )
                      }
                      authors {
                        author {
                          document {
                            ... on PrismicAuthor {
                              id
                              url
                              data {
                                full_name {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      date(formatString: "D MMMM YYYY")
                    }
                    url
                  }
                  ... on PrismicRecipe {
                    id
                    data {
                      title {
                        text
                        richText
                      }
                      short_description {
                        text
                        richText
                      }
                      image {
                        url
                        alt
                        gatsbyImageData(
                          width: 360
                          height: 360
                          placeholder: NONE
                        )
                      }
                      authors {
                        author {
                          document {
                            ... on PrismicAuthor {
                              id
                              url
                              data {
                                full_name {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      date(formatString: "D MMMM YYYY")
                    }
                    url
                  }
                }
              }
            }
            primary {
              curated_posts_subtitle {
                text
                richText
              }
              curated_posts_title {
                text
                richText
              }
            }
          }
          ... on PrismicPostDataBodyBanner {
            id
            primary {
              banner {
                document {
                  ... on PrismicBanner {
                    id
                    _previewable
                    data {
                      background_colour
                      cta_position
                      cta_text
                      cta_url {
                        url
                        target
                        link_type
                      }
                      description {
                        richText
                        text
                      }
                      image {
                        alt
                        gatsbyImageData
                      }
                      text_colour
                      title {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
        }
        category
        date(formatString: "D MMMM YYYY")
      }
    }
    prismicSiteConfig {
      _previewable
      data {
        blog_page_banner {
          document {
            ... on PrismicBanner {
              id
              data {
                title {
                  richText
                }
                text_colour
                image {
                  gatsbyImageData
                  alt
                }
                description {
                  richText
                }
                cta_url {
                  url
                  target
                  link_type
                }
                cta_text
                cta_position
                background_colour
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Post)
