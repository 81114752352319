import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const Byline = ({ authors, date }) => {
  const data = useStaticQuery(graphql`
    query DefaultAuthorQuery {
      prismicAuthor(data: { is_default: { eq: true } }) {
        id
        url
        data {
          full_name {
            text
          }
        }
      }
    }
  `)

  const defaultAuthor = data.prismicAuthor
  const hasAuthors = authors.length > 0 && authors[0].author?.document

  return (
    <div className="post__byline">
      {(hasAuthors ? authors : [defaultAuthor]).map((author, index) => {
        const authorData = author.author?.document || author
        if (authorData) {
          const { full_name } = authorData.data
          return (
            <div className="post__authors">
              <Link key={authorData.id || index} to={authorData.url}>
                {full_name.text}
              </Link>
            </div>
          )
        } else {
          return null
        }
      })}
      {date && <p>{date}</p>}
    </div>
  )
}

Byline.propTypes = {
  authors: PropTypes.array,
  date: PropTypes.string,
}

export default Byline
