import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from "@components/helpers/CustomLink"

import "@styles/components/slices/columns-section.scss"

const Boxes = ({ slice }) => {
  const boxesSection = slice.primary
  const boxes = slice.items

  return (
    <section className="boxes">
      <div className="boxes__wrapper">
        <div className="is-centered">
          <h2>{boxesSection.boxes_title.text}</h2>
        </div>
        <div className="columns-section__columns columns-section__3-columns">
          {boxes.map((box, index) => {
            return (
              <div
                key={`box-column-${index}`}
                className="columns-section__column box"
              >
                <GatsbyImage
                  image={box.box_image.gatsbyImageData}
                  alt={box.box_image.alt}
                />
                <h3>{box.box_title.text}</h3>
                <p>{box.box_description.text}</p>
                <div className={`box-annotation-${index}`}>
                  <span className="box-annotation-text">
                    {box.box_annotation}
                  </span>
                </div>
                <div>
                  <CustomLink
                    className="button button-primary"
                    target={box.cta_link.target}
                    url={box.cta_link.url}
                    type={box.cta_link.link_type}
                    label={box.cta_label}
                  >
                    {box.cta_label}
                  </CustomLink>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Boxes
