import React from "react"
import RichText from "../RichText"
import classnames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from "@components/helpers/CustomLink"

import "@styles/components/slices/columns-section.scss"

const ColumnSection = ({ slice }) => {
  const {
    number_of_columns,
    columns_title,
    columns_subtitle,
    bottom_copy,
  } = slice.primary

  const columnsClassName = classnames(
    "columns-section__columns",
    `columns-section__${number_of_columns.toLowerCase().replace(" ", "-")}`
  )

  const ColumnImage = ({ column }) => {
    if (!column.column_image?.gatsbyImageData) {
      return null
    }

    // wrap image in anchor tag if CTA url is present
    if (column.cta_url && column.cta_url.url) {
      return (
        <CustomLink
          url={column.cta_url.url}
          target={column.cta_url.target}
          type={column.cta_url.link_type}
        >
          <GatsbyImage
            className="columns-section__image"
            image={column.column_image.gatsbyImageData}
            alt={column.column_image.alt || "Oddbox image"}
            objectFit="contain"
          />
        </CustomLink>
      )
    } else {
      return (
        <GatsbyImage
          className="columns-section__image"
          objectFit="contain"
          image={column.column_image.gatsbyImageData}
          alt={column.column_image.alt || "Oddbox image"}
        />
      )
    }
  }

  return (
    <section className="columns-section">
      <div className="columns-section__title">
        <RichText render={columns_title.richText} />
      </div>
      <div className="columns-section__subtitle">
        <RichText render={columns_subtitle.richText} />
      </div>

      <div className={columnsClassName}>
        {slice.items.map((column, index) => {
          return (
            <div key={index} className="columns-section__column">
              <ColumnImage column={column} />
              {column.column_title.text && (
                <RichText render={column.column_title.richText} />
              )}
              {column.column_subtitle.text && (
                <RichText render={column.column_subtitle.richText} />
              )}
              {column.column_text.text && (
                <RichText render={column.column_text.richText} />
              )}
              {column.cta_url && column.cta_url.url && column.cta_text.text && (
                <div className="columns-section__button">
                  <CustomLink
                    className={"button button-primary"}
                    url={column.cta_url.url}
                    target={column.cta_url.target}
                    label={column.cta_text.text}
                    type={column.cta_url.link_type}
                  >
                    {column.cta_text.text}
                  </CustomLink>
                </div>
              )}
            </div>
          )
        })}
      </div>
      {bottom_copy && (
        <div className="columns-section__subtitle">
          <RichText render={bottom_copy.richText} />
        </div>
      )}
    </section>
  )
}

export default ColumnSection
