import React from "react"
import { Elements, RichText } from "prismic-reactjs"
import PropTypes from "prop-types"
import CustomLink from "@components/helpers/CustomLink"
import { linkResolver } from "@utils/linkResolver"

const HtmlSerializer = (type, element, content, children, index) => {
  switch (type) {
    // Add a class to hyperlinks and enable preloading if its an internal link
    case Elements.hyperlink: // link
      return (
        <CustomLink
          key={index}
          className="text-link"
          target={element.data.target}
          url={element.data.url || linkResolver(element.data)}
          type={element.data.link_type}
          label={content}
        >
          {content}
        </CustomLink>
      )

    // Return null to stick with the default behavior
    default:
      return null
  }
}

const RichTextWrapper = ({ render, index }) => (
  <RichText render={render} htmlSerializer={HtmlSerializer} />
)

RichTextWrapper.propTypes = {
  index: PropTypes.string,
  render: PropTypes.any,
}

export default RichTextWrapper
