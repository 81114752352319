import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const CustomLink = props => {
  const { url, target, label, type, className } = props

  const isInternalLink = type === "Document"
  if (isInternalLink) {
    return (
      <Link
        className={className}
        to={url}
        aria-label={label}
        target={target}
        rel={target === "_blank" ? "noopener" : ""}
      >
        {props.children}
      </Link>
    )
  } else {
    return (
      <a
        className={className}
        href={url}
        aria-label={label}
        target={target}
        rel={target === "_blank" ? "noopener" : ""}
      >
        {props.children}
      </a>
    )
  }
}

CustomLink.propTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default CustomLink
