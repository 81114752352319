import React from "react"
import { Link } from "gatsby"

import "@styles/components/slices/category-navigation.scss"

const CategoryNavigation = props => {
  const { slice } = props
  return (
    <div className="category-navigation is-centered">
      {slice.items.map((item, index) => {
        const url = `/${props.fromLocation}${item.tag.url}`
        return (
          <Link
            key={`category-link-${index}`}
            className="category-navigation__link"
            to={url}
            state={{
              fromRecipes:
                props.fromLocation && props.fromLocation === "recipes",
            }}
          >
            {item.tag_alias}
          </Link>
        )
      })}
    </div>
  )
}

export default CategoryNavigation
