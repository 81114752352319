import React from "react"

import RichText from "@components/RichText"
import Trustpilot from "@components/Trustpilot"

import "@styles/components/slices/trustpilot-section.scss"

const TrustpilotSection = ({ slice }) => {
  const {
    trustpilot_title,
    trustpilot_subtitle,
    trustpilot_cta_text,
    trustpilot_cta_url,
    trustpilot_section_type,
  } = slice.primary

  return (
    <section className="trustpilot-section">
      {trustpilot_title?.richText ? (
        <RichText render={trustpilot_title.richText} />
      ) : null}
      {trustpilot_subtitle?.richText ? (
        <RichText render={trustpilot_subtitle.richText} />
      ) : null}

      <Trustpilot template={trustpilot_section_type} theme="light" />

      {trustpilot_cta_url?.url && trustpilot_cta_text?.text && (
        <a
          className="button button-primary"
          href={trustpilot_cta_url.url}
          target={trustpilot_cta_url.target}
        >
          {trustpilot_cta_text.text}
        </a>
      )}
    </section>
  )
}

export default TrustpilotSection
