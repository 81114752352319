import React from "react"

const CodeBlock = ({ slice }) => {
  const code = slice.primary.code_block.text
  // embeds html code on the page
  // NB: This does not execute JS
  return <div dangerouslySetInnerHTML={{ __html: code }} />
}

export default CodeBlock
