import React, { useState } from "react"
import SliceZone from "@components/SliceZone"
import "@styles/components/mobile-navigation.scss"

const MobileNavigation = props => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // add mobile value to slice data
  const navLinks = props.navLinks.body.map(slice => {
    slice.mobile = true
    return slice
  })

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }
  return (
    <nav className="mobile-navigation" aria-label="Mobile Navigation Drawer">
      <button
        className="mobile-navigation__hamburger-icon"
        onClick={handleDrawer}
        aria-label="open"
      >
        <svg viewBox="0 0 30 18" width="30" height="18">
          <rect width="30" height="2"></rect>
          <rect y="8" width="30" height="2"></rect>
          <rect y="16" width="30" height="2"></rect>
        </svg>
      </button>
      <div
        className={`mobile-navigation__drawer${isDrawerOpen ? "--open" : ""}`}
      >
        <div className="mobile-navigation__wrapper">
          <button
            className="mobile-navigation__close"
            onClick={handleDrawer}
            aria-label="close"
          />
          <ul className="navigation__links">
            <SliceZone sliceZone={navLinks} mobile={true} />
            <li className="navigation__link">
              <a
                className="button button-secondary"
                href={process.env.GATSBY_SHOP_URL}
              >
                Subscribe
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default MobileNavigation
