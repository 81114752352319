/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  title,
  location,
  nextPage,
  prevPage,
  canonicalLink,
  children
}) {
  const { site, prismicSiteConfig: siteConfig } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        prismicSiteConfig {
          data {
            site_meta_description
            site_meta_title
          }
        }
      }
    `
  )

  const metaDescription =
    description ||
    siteConfig.data.site_meta_description ||
    site.siteMetadata.description
  const defaultTitle =
    siteConfig.data.site_meta_title || site.siteMetadata?.title

  return (
    <>
      <title>{title ? title : defaultTitle}</title>
      <html lang={lang} />
      {location && 
        <link rel='canoncial' href={canonicalLink
          ? `${site.siteMetadata.siteUrl + canonicalLink}`
          : `${site.siteMetadata.siteUrl + location.pathname}`} />
      }
      {nextPage && 
        <link rel='next' href={`${site.siteMetadata.siteUrl}${nextPage}`} />
      }
      {prevPage && 
        <link rel='prev' href={`${site.siteMetadata.siteUrl}${prevPage}`} />
      }
      <meta name={`description`} content={metaDescription} />
      <meta property={`og:title`} content={title} />
      <meta property={`og:description`} content={metaDescription} />
      <meta property={`og:type`} content={`website`} />
      <meta property={`twitter:card`} content={`summary`} />
      <meta property={`twitter:creator`} content={site.siteMetadata?.author || ``} />
      <meta property={`twitter:title`} content={title} />
      <meta property={`twitter:description`} content={metaDescription} />
      {children}
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  location: PropTypes.object,
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
}

export default SEO
