import React from "react"
import RichText from "@components/RichText"
import Accordion from "@components/Accordion"

import "@styles/components/slices/accordion-section.scss"

const AccordionSection = ({ slice }) => {
  const { accordion_title, accordion_subtitle } = slice.primary

  return (
    <section className="accordion-section">
      <div className="accordion-section__title">
        <RichText render={accordion_title.richText} />
      </div>
      <div className="accordion-section__subtitle">
        <RichText render={accordion_subtitle.richText} />
      </div>
      {slice.items.map((item, index) => {
        return (
          <Accordion
            key={index}
            title={<RichText render={item.accordion_item_title.richText} />}
            index={`${index}`}
            alt={false}
          >
            <RichText render={item.accordion_item_text.richText} />
          </Accordion>
        )
      })}
    </section>
  )
}

export default AccordionSection
