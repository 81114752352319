import React from "react"
import SliceZone from "@components/SliceZone"
import "@styles/components/navigation.scss"

const Navigation = props => {
  return (
    <nav className="navigation">
      <ul className="navigation__links">
        <SliceZone sliceZone={props.navLinks.body} />
        <li className="navigation__link">
          <a
            className="button button-primary"
            href={process.env.GATSBY_SHOP_URL}
          >
            Subscribe
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
