import React, { useEffect, useRef } from "react"
import { Script } from "gatsby"
import "@styles/components/slices/trustpilot-section.scss"

function Trustpilot(props) {
  const { template, theme } = props

  const ref = useRef(null)
  useEffect(() => {
    // if window.Trustpilot is available, load the trustbox
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  // Trustbox widget configs
  const templates = {
    Feed: { id: "539adbd6dec7e10e686debee", height: "500px" },
    Score: { id: "53aa8807dec7e10d38f59f32", height: "120px" },
    Micro: { id: "5419b732fbfb950b10de65e5", height: "20px" },
    MicroCombo: { id: "5419b6ffb0d04a076446a9af", height: "20px" },
  }

  // configure trustbox attributes
  const attributes = {
    className: "trustpilot-widget",
    "data-template-id": `${templates[template].id}`,
    "data-locale": "en-GB",
    "data-businessunit-id": process.env.GATSBY_TRUSTPILOT_BUSINESS_ID,
    "data-style-height": `${templates[template].height}`,
    "data-style-width": "100%",
    "data-stars": "4,5",
    "data-theme": `${theme || "light"}`,
    "data-review-languages": "en",
  }

  return (
    <div ref={ref} {...attributes}>
      <a
        href="https://uk.trustpilot.com/review/www.oddbox.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        Trustpilot
      </a>
      <Script
        type="text/javascript"
        id="trustpilot"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async=""
      />
    </div>
  )
}

export default Trustpilot
