import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { validatePostcode } from "@components/helpers/PostcodeCheck"
import { Link, navigate } from "gatsby"
import Trustpilot from "@components/Trustpilot"
import "@styles/components/slices/hero-postcode-section.scss"

const HeroPostcodeCheck = ({ slice }) => {
  const [postcodeInput, setPostcodeInput] = useState("")
  const [showNoDeliveryMessage, setShowNoDeliveryMessage] = useState(false)
  const [showSignupButton, setShowSignupButton] = useState(false)
  const [postcodeDeliveryData, setPostcodeDeliveryData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const hero = slice.primary

  const handlePostcodeChange = e => {
    e.preventDefault()
    setPostcodeInput(e.target.value)
  }

  const handlePostcodeCheck = async e => {
    e.preventDefault()
    setIsLoading(true)

    const data = await validatePostcode(postcodeInput)

    if (data) {
      setPostcodeDeliveryData(data)
      setShowSignupButton(true)
      setIsLoading(false)
    } else {
      setShowNoDeliveryMessage(true)
      setIsLoading(false)
    }
  }

  const resetForm = () => {
    setPostcodeInput("")
    setShowNoDeliveryMessage(false)
    setPostcodeDeliveryData({})
  }

  const handleSubmit = e => {
    e.preventDefault()

    const registrationUrl = postcodeDeliveryData.deliveryConfigurationId
      ? encodeURI(
          `${process.env.GATSBY_SHOP_URL}?postcode=${postcodeDeliveryData.postcode}`
        )
      : encodeURI(`${process.env.GATSBY_SHOP_URL}`)

    navigate(registrationUrl)
  }

  return (
    <section className="hero-postcode">
      <div className="hero-postcode__wrapper">
        <div className="hero-postcode__intro">
          <div className="hero-postcode__title">
            <h1>{hero.title}</h1>
          </div>
          {isLoading && <div className="loader" />}
          {!showNoDeliveryMessage && !showSignupButton && !isLoading && (
            <div className="hero-postcode__postcode-check">
              <p>{hero.cta_description.text}</p>
              <div className="hero-postcode__postcode-check-form-group">
                <form onSubmit={handlePostcodeCheck}>
                  <input
                    className="hero-postcode__postcode-check-input"
                    type="text"
                    placeholder="Full Postcode"
                    value={postcodeInput}
                    onChange={handlePostcodeChange}
                  />
                  <input
                    type="submit"
                    value={hero.cta_text}
                    className="button button-primary"
                  ></input>
                </form>
              </div>
            </div>
          )}

          {showSignupButton && (
            <div className="hero-postcode__postcode-check">
              <p>
                Good news!
                <br />
                We deliver to your area overnight every{" "}
                <span className="underline">{postcodeDeliveryData.day}</span>
              </p>
              <button
                onClick={handleSubmit}
                className="button button-primary"
                type="submit"
                value="Pick your box"
              >
                Pick your box
              </button>
            </div>
          )}

          {showNoDeliveryMessage && (
            <div className="hero-postcode__postcode-check">
              <p>
                Sorry, we don't seem to deliver to your postcode:{" "}
                {postcodeInput}
              </p>
              <p>
                If you'd like to be notified as soon as we deliver to your area,
                please join our waiting list below.
              </p>
              <Link className="button button-primary" to="/postcode-waitlist">
                Join our waiting list
              </Link>
              <button
                className="hero-postcode__try-again-link text-link"
                onClick={resetForm}
              >
                Try another postcode
              </button>
            </div>
          )}
        </div>
        <div className="hero-postcode__image">
          <GatsbyImage
            image={hero.hero_image.gatsbyImageData}
            alt={hero.hero_image.alt}
          />
        </div>
      </div>
      <Trustpilot template="Micro" theme="dark" />
    </section>
  )
}

export default HeroPostcodeCheck
