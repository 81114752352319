import React, { useState } from "react"
import { Link } from "gatsby"
import classnames from "classnames"

const NavigationLink = ({ slice, mobile }) => {
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false)

  const FormattedLink = ({ navLink, onClick, onKeyDown }) => {
    if (navLink.link.link_type === "Document") {
      return (
        <Link
          to={navLink.link.url}
          target={navLink.link.target}
          onKeyDown={onKeyDown}
          onClick={onClick}
        >
          {navLink.link_label}
        </Link>
      )
    } else {
      return (
        <a href={navLink.link.url} target={navLink.link.target}>
          {navLink.link_label}
        </a>
      )
    }
  }

  // mobile dropdown menu logic
  const handleMobileDropdown = e => {
    if ((e.type === "keydown" && e.key === "Enter") || e.type === "click") {
      if (slice.items.length > 0) {
        setIsMobileDropdownOpen(!isMobileDropdownOpen)
      }
    }
  }

  // add conditional classes
  const navigationLinkClasses = classnames("navigation__link", {
    dropdown: slice.items.length > 0 && !isMobileDropdownOpen,
    "dropdown--open": slice.items.length && isMobileDropdownOpen,
  })

  // add conditional attributes for mobile dropdown menu
  const navigationLinkAttributes = () => {
    if (!mobile) {
      return
    }

    let attributes = {}
    if (slice.items.length > 0) {
      attributes["aria-expanded"] = isMobileDropdownOpen
      attributes["aria-haspopup"] = true
      attributes.role = "button"
    }
    return attributes
  }

  const navigatinLinkHandlers = () => {
    if (mobile) {
      return {
        onClick: handleMobileDropdown,
        onKeyDown: handleMobileDropdown,
      }
    } else {
      return {}
    }
  }

  if (slice.items.length > 0) {
    return (
      <li
        className={navigationLinkClasses}
        {...navigationLinkAttributes()}
        {...navigatinLinkHandlers()}
      >
        <span tabIndex="0">{slice.primary.link_label}</span>
        <ul className="navigation__dropdown-links">
          {slice.items.map(link => (
            <li key={link.link_label} className="navigation__link">
              <FormattedLink navLink={link} />
            </li>
          ))}
        </ul>
      </li>
    )
  } else {
    return (
      <li className={navigationLinkClasses} {...navigationLinkAttributes()}>
        {slice.items.length === 0 && (
          <FormattedLink
            navLink={slice.primary}
            onClick={slice.items.length > 0 ? handleMobileDropdown : null}
            {...navigatinLinkHandlers}
          />
        )}
      </li>
    )
  }
}

export default NavigationLink
