import React from "react"
import RichText from "@components/RichText"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomLink from "@components/helpers/CustomLink"

const FeatureCard = ({ slice }) => {
  const card = slice.primary
  return (
    <section className="feature-card">
      <div className="feature-card__wrapper">
        <div className="feature-card__columns">
          <div className="feature-card__image">
            <GatsbyImage
              image={card.feature_image.gatsbyImageData}
              alt={card.feature_image.alt}
            />
          </div>
          <div className="feature-card__copy">
            <div className="feature-card__title">
              <h2>{card.feature_title.text}</h2>
            </div>
            <div className="feature-card__description">
              <RichText render={card.feature_description.richText} />
            </div>
            <CustomLink
              className="button button-primary"
              target={card.feature_cta_link.target}
              url={card.feature_cta_link.url}
              type={card.feature_cta_link.link_type}
              label={card.feature_cta_label}
            >
              {card.feature_cta_label}
            </CustomLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureCard
