import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "@styles/components/slices/image-gallery.scss"

const ImageGallery = ({ slice }) => {
  return (
    <div className="image-gallery">
      {slice.items.map((image, index) => {
        if (image.gallery_image?.gatsbyImageData) {
          return (
            <div
              key={`image-gallery-${index}`}
              className="image-gallery__image-wrapper"
            >
              <GatsbyImage
                className="image-gallery__image"
                image={image.gallery_image.gatsbyImageData}
                alt={image.gallery_image.alt || "Oddbox Gallery Image"}
              />
            </div>
          )
        } else return null
      })}
    </div>
  )
}

export default ImageGallery
