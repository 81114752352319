import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import CustomLink from "./helpers/CustomLink"

import "@styles/components/banner.scss"
import { useScreenMediaQuery } from "../hooks/useMediaQuery.hook"

const Banner = props => {
  const {
    title,
    description,
    cta_text,
    cta_url,
    cta_position,
    image,
    text_colour,
    background_colour,
  } = props

  // set default colours if not set
  const colour = text_colour || "#ffffff"
  const background = background_colour || "#2e5a36"

  const { isDownMD } = useScreenMediaQuery()

  return (
    <div
      className="banner container"
      style={{
        flexDirection: isDownMD
          ? "column"
          : cta_position
          ? "row"
          : "row-reverse",
        backgroundColor: background,
      }}
    >
      <div className="banner__image">
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt || "Oddbox image"}
        />
      </div>
      <div className="banner__copy" style={{ color: colour }}>
        <RichText render={title.richText} />
        <div className="banner__description">
          <RichText render={description.richText} />
        </div>
      </div>
      <div className="banner__cta">
        <CustomLink
          className={"button button-primary"}
          url={cta_url.url}
          target={cta_url.target}
          label={cta_text}
          type={cta_url.link_type}
        >
          {cta_text}
        </CustomLink>
      </div>
    </div>
  )
}

Banner.propTypes = {
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  cta_text: PropTypes.string.isRequired,
  cta_url: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  background_colour: PropTypes.string,
  text_colour: PropTypes.string,
  cta_position: PropTypes.bool,
}

export default Banner
