import React from "react"
import RichText from "@components/RichText"
import ImageGallery from "react-image-gallery"
import { GatsbyImage } from "gatsby-plugin-image"

import "@styles/components/slices/image-gallery.scss"
import "@styles/components/slices/carousel-section.scss"

const CarouselSection = ({ slice }) => {
  const { title1: title, subtitle } = slice.primary

  // format images to be passed to ImageGallery
  const images = slice.items.map(image => {
    // build GatbsyImage to display in the carousel
    const imageRender = () => {
      return (
        <div className="carousel-section__slide">
          <GatsbyImage
            className={"carousel-section__slide-image"}
            image={image.carousel_image.gatsbyImageData}
            alt={image.carousel_image.alt || "Oddbox carousel image"}
          />
          {image.description && (
            <div className="image-gallery-description">
              <p>{image.description}</p>
            </div>
          )}
        </div>
      )
    }
    return {
      renderItem: imageRender,
    }
  })

  return (
    <section className="carousel-section">
      <h1 className="is-centered">{title.text}</h1>
      <RichText render={subtitle.richText} />
      <ImageGallery
        items={images}
        lazyLoad={true}
        showFullscreenButton={false}
        showBullets={true}
        showPlayButton={false}
      />
    </section>
  )
}

export default CarouselSection
