import axios from "axios"

const deliveryDay = {
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
  Sun: "Sunday",
}

// setup axios config
const config = {
  headers: {
    "X-API-KEY": process.env.GATSBY_PACKHOUSE_X_TOKEN,
  },
}

// const api urls
const packhouseApiBaseUrl = process.env.GATSBY_PACKHOUSE_API
const validatePostcodeUrl = `${packhouseApiBaseUrl}/validate_post_code`
const deliveryConfigurationUrl = `${packhouseApiBaseUrl}/delivery_configuration`

// remove any spaces and convert to uppercase
export const formatPostcode = rawPostcode => {
  const sanitizedPostcode = rawPostcode.toUpperCase().replace(/\s/g, "")

  // then add a space before the final 3 characters
  const formattedPostcode =
    sanitizedPostcode.slice(0, sanitizedPostcode.length - 3) +
    " " +
    sanitizedPostcode.slice(
      sanitizedPostcode.length - 3,
      sanitizedPostcode.length
    )

  return formattedPostcode
}

// extract outward code and district by removing last 2 characters
export const getOutwardCode = postcode => {
  return postcode.substring(0, postcode.length - 2)
}

export const validatePostcode = async postcode => {
  // configure postcode and API call body
  const formattedPostcode = formatPostcode(postcode)
  const body = { post_code: formattedPostcode }

  try {
    const response = await axios.post(validatePostcodeUrl, body, config)

    // if status and postcode is valid get delivery info
    if (response.status === 200 && response.data.valid) {
      const outwardCode = getOutwardCode(formattedPostcode)

      // if delivery route is valid return valid postcode with delivery day
      const getDeliveryData = await axios.get(
        `${deliveryConfigurationUrl}?postcode=${encodeURIComponent(
          outwardCode
        )}`,
        config
      )

      if (getDeliveryData.status === 200) {
        // get full delivery day
        const getDeliveryDay = getDeliveryData.data.list[0].delivery_day
        const fullDeliveryDay = deliveryDay[getDeliveryDay]
        const deliveryConfigurationId = getDeliveryData.data.list[0].id

        return {
          postcode: formattedPostcode,
          day: fullDeliveryDay,
          deliveryConfigurationId: deliveryConfigurationId,
        }
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (err) {
    console.error(err)
    return false
  }
}
