import React from "react"

import "@styles/components/slices/accordion-section.scss"
import Banner from "@components/Banner"

const BannerSection = ({ slice }) => {
  const {
    title,
    description,
    cta_text,
    cta_url,
    cta_position,
    image,
    text_colour,
    background_colour,
  } = slice.primary.banner.document.data

  return (
    <section className="banner-section">
      <Banner
        title={title}
        description={description}
        cta_text={cta_text}
        cta_url={cta_url}
        cta_position={cta_position}
        text_colour={text_colour}
        background_colour={background_colour}
        image={image}
      />
    </section>
  )
}

export default BannerSection
