import React from "react"
import RichText from "@components/RichText"

import "@styles/components/slices/richtext-section.scss"

const RichTextSection = ({ slice }) => {
  return (
    <section
      className={
        slice.slice_label === "centre_align"
          ? "richtext-section container is-centered"
          : "richtext-section container"
      }
    >
      {slice.items.map((item, index) => (
        <RichText
          key={index}
          index={`${index}`}
          render={item.body_text.richText}
        />
      ))}
      <div style={{ clear: "both" }}></div>
    </section>
  )
}

export default RichTextSection
