import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/fontawesome-free-regular"
import "../styles/components/zendesk-widget.scss"

const ZendeskWidget = () => {
  const [isLoading, setIsLoading] = useState(false)

  // open the chat widget
  const openWidget = () => {
    if (window.zE?.activate) {
      window.zE.activate()
    }
    setIsLoading(false)
  }

  // when available hide zE default button
  const configureZendesk = () => {
    const zendeskHideMonitor = setInterval(() => {
      if (window.zE.hide) {
        window.zE.hide()
        openWidget()
        clearInterval(zendeskHideMonitor)
      }
    }, 100)
  }

  // wait for zE widget to load
  const zendeskLoading = () => {
    const zendeskMonitor = setInterval(() => {
      if (window.zE) {
        configureZendesk()
        clearInterval(zendeskMonitor)
      }
    }, 100)
  }

  const handleClick = () => {
    setIsLoading(true)
    if (!window.zE) {
      // setup script
      const script = document.createElement("script")
      script.async = true
      script.id = "ze-snippet"
      script.addEventListener("load", zendeskLoading)
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=cc5a5129-3554-4579-b4a9-a9dfc40114ee"

      document.head.appendChild(script)
    } else {
      openWidget()
    }
  }

  return (
    <button onClick={handleClick} className="zd-widget">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          <FontAwesomeIcon icon={faQuestionCircle} />{" "}
          <span className="zd-widget_text">help</span>
        </>
      )}
    </button>
  )
}

export default ZendeskWidget
