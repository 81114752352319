import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import RichText from "@components/RichText"

import "@styles/components/slices/instagram-section.scss"

const InstagramSection = ({ slice }) => {
  const {
    instagram_title,
    instagram_subtitle,
    instagram_cta_url,
    instagram_cta_text,
  } = slice.primary

  const { allIgPost } = useStaticQuery(
    graphql`
      query {
        allIgPost(limit: 6) {
          nodes {
            id
            media_url
            permalink
            image {
              childImageSharp {
                gatsbyImageData(width: 300, height: 300, placeholder: NONE)
              }
            }
          }
        }
      }
    `
  )

  return (
    <section className="instagram-section">
      <RichText render={instagram_title.richText} />
      <RichText render={instagram_subtitle.richText} />
      <div className="instagram-section__container">
        {allIgPost.nodes.map(post => {
          return (
            <a
              key={post.id}
              className="instagram-section__image"
              href={post.permalink}
              target="_blank"
              rel="noreferrer"
            >
              <GatsbyImage
                image={getImage(post.image)}
                alt="Instagram post"
              />
            </a>
          )
        })}
      </div>
      {instagram_cta_url.url && instagram_cta_text.text && (
        <a href={instagram_cta_url.url} target={instagram_cta_url.target}>
          <button className="button button-primary">
            {instagram_cta_text.text}
          </button>
        </a>
      )}
    </section>
  )
}

export default InstagramSection
