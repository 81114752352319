import React, { useEffect } from "react"
import { parse } from "query-string"
import { getCookie, setCookie } from "./Cookies"


const CookieWrapper = ({ children, location }) => {
    // Store coupon in cookie if in query string
    useEffect(() => {

        let parsedQuery = parse(location.search)
        let COUPON_COOKIE_NAME = "ob_coupon"
        let SKU_COOKIE_NAME = "ob_sku"

        if (parsedQuery.coupon) {
            setCookie(COUPON_COOKIE_NAME, parsedQuery.coupon, 1, process.env.GATSBY_DOMAIN) // expires after 24 hours
        }

        if (parsedQuery.sku) {
          setCookie(SKU_COOKIE_NAME, parsedQuery.sku, 1, process.env.GATSBY_DOMAIN)
        }
    }, [location])

    useEffect(() => {
        let COOKIE_NAME = "ob_landing_url"

        if (!getCookie(COOKIE_NAME)) {
            setCookie(COOKIE_NAME, window.location.href, 1, process.env.GATSBY_DOMAIN) // expires after 24 hours
        }
    }, [])

    return <>{children}</>
}

export default CookieWrapper
