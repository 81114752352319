exports.linkResolver = doc => {
  // send broken links to 404 page
  if (doc.isBroken || !doc.uid) {
    return `/404`
  }

  // strip double dashes and ellipis from urls which are created when titles have special characters
  // the page builder does this automatically so we need to do it to ensure the urls match
  const formattedURL = doc.uid.replace("--", "-").replace("...", "")

  switch (doc.type) {
    case "homepage":
      return `/`
    case "page":
      return `/${formattedURL}`
    case "post":
      return `/blog/${formattedURL}`
    case "recipe":
      return `/recipes/${formattedURL}`
    case "blog_index":
      return `/blog`
    case "product":
      return `/boxes/${formattedURL}`
    case "recipe_index":
      return `/recipes`
    case "tag":
      return `/categories/${formattedURL}`
    case "author":
      return `/authors/${formattedURL}`
    default:
      return `/${formattedURL}`
  }
}
