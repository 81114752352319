import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import Byline from "@components/Byline"

import "@styles/components/slices/curated-posts-section.scss"
import "@styles/components/slices/columns-section.scss"

const BlogPostCard = props => {
  const { title, short_description, image, date, authors, url } = props.blogPost

  const defaultImage = useStaticQuery(
    graphql`
      query {
        imageSharp(
          original: { src: { regex: "/Oddbox_Logo_default-image/" } }
        ) {
          gatsbyImageData(width: 360, height: 360, placeholder: NONE)
        }
      }
    `
  )

  return (
    <article className="columns-section__column curated-posts-section__post">
      <Link to={url}>
        <GatsbyImage
          className="curated-posts-section__post-image"
          image={
            image.gatsbyImageData || defaultImage.imageSharp.gatsbyImageData // replace with default image if no image is provided
          }
          alt={image.alt || "Oddbox image"}
        />

        <div className="curated-posts-section__post-title">
          <h3>{title.text}</h3>
        </div>
      </Link>
      {!props.hideByline && <Byline authors={authors} date={date} />}
      {!props.hideDescription && (
        <div className="curated-posts-section__post-description">
          <p>{short_description.text}</p>
        </div>
      )}
    </article>
  )
}

export default BlogPostCard
